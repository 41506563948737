// Well hello there. Looks like we don't have any Javascript.
// Maybe you could help a friend out and put some in here?
// Or at least, when ready, this might be a good place for it.
import "./**/*.js";
import "../components/**/*.js";

var goBack = document.querySelector(".go-back > button, .go-back > a ");

if (goBack) {
  goBack.addEventListener("click", (e) => {
    e.preventDefault();
    window.history.go(-1);
  });
}
document.querySelectorAll(".faq-container .question").forEach((item) => {
  item.addEventListener("click", (e) => {
    e.preventDefault();
    const isOpen = e.currentTarget.classList.contains("open");
    document.querySelectorAll(".faq-container .question").forEach((item) => {
      item.classList.remove("open");
    });
    if (isOpen) {
      e.currentTarget.classList.remove("open");
    } else {
      e.currentTarget.classList.add("open");
    }
  });
});
