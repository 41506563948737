const modalTriggers = document.querySelectorAll(".Modal__trigger");
const overlay = document.querySelector(".Modal__overlay");
const body = document.body;

if (modalTriggers.length) {
  modalTriggers.forEach((trigger) => {
    trigger.addEventListener("click", () => {
      const { popupTrigger } = trigger.dataset;
      const popupModal = document.querySelector(
        `[data-modal="${popupTrigger}"]`
      );

      popupModal.classList.add("is--visible");
      overlay.classList.add("is-blacked-out");
      body.classList.add("modal-open");

      setModalCenter(`[data-modal="${popupTrigger}"]`);

      popupModal
        .querySelector(".Modal__close")
        .addEventListener("click", () => {
          popupModal.classList.remove("is--visible");
          overlay.classList.remove("is-blacked-out");
          body.classList.remove("modal-open");
        });

      overlay.addEventListener("click", () => {
        popupModal.classList.remove("is--visible");
        overlay.classList.remove("is-blacked-out");
        body.classList.remove("modal-open");
      });
    });
  });
}

const setModalCenter = (classORid) => {
  const div = document.querySelector(classORid);

  const height = div.offsetHeight;
  const innerHeight = window.innerHeight;

  div.style.top = (innerHeight - height) / 2 + window.pageYOffset + "px";
};
